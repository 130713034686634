
export default {
  props:['elements'],
  data() {
    return {
      menuOpen: false,
      mega_menu: this.prepareMega(),
      searchOpen: false,
      search: "",
    };
  },
  methods: {
    prepareMega() {
      let temp = JSON.parse(
        JSON.stringify(this.$store.getters.store.eshop.mega_menu)
      );
      temp = temp.map((c) => {
        let full = false;
        c.children.forEach((cc) => {
          if (cc.children && cc.children.length) {
            full = true;
          }
        });
        c.full = full;
        return c;
      });
      return temp;
    },
    doSearch() {
      let query = [];
      if (this.search) {
        query.push(`q=${this.search}`);
      }

      this.searchFocus = false;
      this.$router.push({ path: `/search/+${query.join("+")}` });
    },
  },
};
